const APP_SETTINGS = {
  /*
  * These values are replaced using evnsubst during container startup
  * every dollar value here should correspond to an env value in envConfigMap.yml
  */
  SGW_VERSION: 'c641dc25',
  ROLLBAR_TOKEN: '',
  SILVERBEAK_GRIFFIN_SERVICE: 'https://api.tabletop.wizards.com/silverbeak-griffin-service',
  MAX_PLAYER_CAPACITY: '500',
  EMS_ENDPOINT: 'https://utbimmyv8f.execute-api.us-west-2.amazonaws.com/prod',
  BASE_URL: '/',
  MIN_QUEUE_SIZE: 2,
  MAX_QUEUE_SIZE: 6,
  MAX_QUEUES_ALLOWED: 10,

  PLATFORM_GAME_ID: 'eventreporter',
  PLATFORM_CLIENT_ID: 'WJRYDHNGROIZHL8B',
  /* labeled a "secret" this is actually a public value */
  PLATFORM_CLIENT_SECRET: 'V5VXK6FLG1YI0GD2XY3H',
  CONTENTFUL_PATH: 'https://app.contentful.com/spaces/88dbjhw5nycw',
}

export default APP_SETTINGS

export type APP_SETTINGS_TYPE = typeof APP_SETTINGS;

export function getAppVar (key: keyof APP_SETTINGS_TYPE) {
  const appVar = import.meta.env[key] || APP_SETTINGS[key]

  // Vite forces extra quotes on meta.env vars when loaded via defineConfig
  // which only show up when doing a full build
  // we need to ensure they are removed
  return typeof appVar === 'string' ? appVar.replaceAll('"', '') : appVar
}
